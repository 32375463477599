import React from 'react'
import Layout from "../components/App/Layout"
import Footer from "../components/App/Footer"
import NavbarStaging from "../components/App/NavbarStaging"
import DetailsContent from '../components/BlogContent/DetailsContent'

const MasterBedroom = () => {
    return (
        <Layout>
            <NavbarStaging />
            <DetailsContent />
            <Footer />
        </Layout>
    )
}

export default MasterBedroom;