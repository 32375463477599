import React from 'react'
import {Link} from 'gatsby'
import img4 from '../../assets/images/blog/blog-img4.jpg'
import img5 from '../../assets/images/blog/blog-img5.jpg'
import img7 from '../../assets/images/blog/blog-img7.jpg'
import user1 from '../../assets/images/user1.jpg'

const DetailsContent = () => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    return (
        <section className="blog-details-area ptb-40">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <i className='bx bx-folder-open'></i>
                                            <span>Category</span>
                                            <Link to="/blog">
                                                Fashion
                                            </Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-group'></i>
                                            <span>View</span>
                                            <Link to="#">
                                                813,454
                                            </Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-calendar'></i>
                                            <span>Last Updated</span>
                                            <Link to="#">
                                                25/04/2020
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <h3>Master Bedroom</h3>
                                <h5>Example 1</h5>

                                <p></p>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="single-gallery-item">
                                            <Link 
                                                to="#"
                                                onClick={e => {
                                                    e.preventDefault(); 
                                                    setIsOpenImage(true); 
                                                    setPhotoIndex(0);}
                                                }
                                            >
                                                <img src={img4} alt="event" />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="single-gallery-item">
                                            <Link 
                                                to="#"
                                                onClick={e => {
                                                    e.preventDefault(); 
                                                    setIsOpenImage(true); 
                                                    setPhotoIndex(1);}
                                                }
                                            >
                                                <img src={img5} alt="event" />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="single-gallery-item">
                                            <Link 
                                                to="#"
                                                onClick={e => {
                                                    e.preventDefault(); 
                                                    setIsOpenImage(true); 
                                                    setPhotoIndex(1);}
                                                }
                                            >
                                                <img src={img7} alt="event" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="article-footer">
                                <div className="article-tags">
                                    <span><i className='bx bx-purchase-tag'></i></span>
                                    <Link to="/blog">Fashion</Link>
                                </div>

                                <div className="article-share">
                                    <ul className="social">
                                        <li><span>Share:</span></li>
                                        <li>
                                            <Link to="https://www.facebook.com/" className="facebook" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-facebook'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://twitter.com/" className="twitter" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-twitter'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.instagram.com/" className="linkedin" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-instagram'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.linkedin.com/" className="instagram" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-linkedin'></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="article-author">
                                <div className="author-profile-header"></div>
                                <div className="author-profile">
                                    <div className="author-profile-title">
                                        <img src={user1} className="shadow-sm" alt="uu" />
                                        <h4>Jing Xue</h4>
                                        <span className="d-block">Photographer, Author, Writer</span>
                                        <p>Jing is.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DetailsContent